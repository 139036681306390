import * as React from "react"
import { graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import { FaSpotify } from "@react-icons/all-files/fa/FaSpotify"
import { FaApple } from "@react-icons/all-files/fa/FaApple"
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Billboard from "../components/billboard"
import Img from "gatsby-image"

const IndexPage = props => (
  <Layout location={props.location}>
    <Seo
      title="OFFICIAL WEBSITE"
      image={props.data.xandorImage.childImageSharp.fluid}
      keywords={[
        `XANDOR`,
        `Xandor`,
        `Music`,
        `Xandor Music`,
        `Xandor EDM`,
        `Xandor Artist`,
        `Xandor Musical Artist`,
        `Xandor Instagram`,
        `Xandor Spotify`,
        `Xandor Official website`,
        `Xandor Soundcloud`,
        `Xandor DJ`,
        `Xandor Boston`,
        `Xandor Xicay`,
        `Xandormusic`,
        `Xandor electronic music`,
      ]}
    />
    <Billboard image={props.data.heroImage.childImageSharp.fluid} />
    <Container>
      <section className="about" id="about">
        <Row className=" align-items-center">
          <Col className="" lg="4" sm="12">
            <Img
              className="rounded-circle"
              fluid={props.data.xandorImage.childImageSharp.fluid}
              alt="Xandor Profile"
            />
          </Col>
          <Col className="about-text-wrapper" sm="12" lg="8">
            <h1 className="text-left mb-4">About Xandor</h1>
            <p>
              <strong>XANDOR</strong> is a music producer and DJ igniting the
              current and future house pop music scene with his unique silky,
              sensual soundscapes.
            </p>
            <p>
              The son of a renowned saxophone player, music has played a big
              part in <strong>Xandor's</strong> life. Xandor's inspiration to
              make electronic music started at a young age with a passion that
              led to professional training and over years of focused dedication.
            </p>
            <p>
              He has performed at some of the most popular venues in Boston.{" "}
              <strong>XANDOR</strong> released his debut collaboration with Will
              Jay, titled "Meet You There," an EDM/Pop track that has garnered
              over 200k streams across 92 countries worldwide.
            </p>
            <Link to="/about" className="btn btn-outline-primary">
              Learn more about XANDOR
            </Link>
          </Col>
        </Row>
      </section>
      <section className="releases" id="releases">
        <Row>
          <Col lg="12">
            <h1 className="text-center  mt-3 mb-4">Popular Releases</h1>
          </Col>
          <Col sm="12" md="6" lg="4" className="mt-4">
            <Img
              className="rounded-0"
              fluid={props.data.whosInYourHeartCover.childImageSharp.fluid}
              alt="XANDOR Single Who's In Your Heart"
            />
            <h2 className="title">Who's In Your Heart (feat. Rapta)</h2>
            <p className="tag">2022 &nbsp;·&nbsp; Single</p>
            <div className="services">
              Listen:
              <ul>
                <li>
                  <a
                    type="text/html"
                    aria-label="Xandor Alive Spotify"
                    href="https://open.spotify.com/track/3Aew6VgYFRSmdjcJk6WDJK?si=af580d640ea645f4"
                  >
                    <FaSpotify /> Spotify
                  </a>
                </li>
                <li>
                  <a
                    type="text/html"
                    aria-label="Xandor Alive Apple Music"
                    href="https://music.apple.com/us/album/whos-in-your-heart-feat-rapta/1631425298?i=1631425299"
                  >
                    <FaApple /> Apple Music
                  </a>
                </li>
                <li>
                  <a
                    type="text/html"
                    aria-label="Xandor Alive Youtube"
                    href="https://youtu.be/mJABf46nfhg"
                  >
                    <FaYoutube /> Youtube
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col sm="12" md="6" lg="4" className="mt-4">
            <Img
              className="rounded-0"
              fluid={props.data.aliveCover.childImageSharp.fluid}
              alt="XANDOR Single Alive"
            />
            <h2 className="title">Alive</h2>
            <p className="tag">2022 &nbsp;·&nbsp; Single</p>
            <div className="services">
              Listen:
              <ul>
                <li>
                  <a
                    type="text/html"
                    aria-label="Xandor Alive Spotify"
                    href="https://open.spotify.com/track/5RyHENcz8Ffqlc5XmcIY4t?si=3d4283c6cdb54aa2"
                  >
                    <FaSpotify /> Spotify
                  </a>
                </li>
                <li>
                  <a
                    type="text/html"
                    aria-label="Xandor Alive Apple Music"
                    href="https://music.apple.com/us/album/alive/1601541762?i=1601541763"
                  >
                    <FaApple /> Apple Music
                  </a>
                </li>
                <li>
                  <a
                    type="text/html"
                    aria-label="Xandor Alive Youtube"
                    href="https://www.youtube.com/watch?v=GPKHunFPbH4"
                  >
                    <FaYoutube /> Youtube
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col sm="12" md="6" lg="4" className="mt-4">
            <Img
              className="rounded-0"
              fluid={props.data.meetYouThereCover.childImageSharp.fluid}
              alt="XANDOR Single Meet You There"
            />
            <h2 className="title">Meet You There (feat. Will Jay)</h2>
            <p className="tag">2020 &nbsp;·&nbsp; Single</p>
            <div className="services">
              Listen:
              <ul>
                <li>
                  <a
                    type="text/html"
                    aria-label="Xandor Meet You There (ft. Will Jay) Spotify"
                    href="https://open.spotify.com/track/6tTMMZRBvxbdD2dxyDLCYE?si=3bb530155c034594"
                  >
                    <FaSpotify /> Spotify
                  </a>
                </li>
                <li>
                  <a
                    type="text/html"
                    aria-label="Xandor Meet You There (ft. Will Jay) Apple Music"
                    href="https://music.apple.com/us/album/meet-you-there-feat-will-jay/1495119674?i=1495119678"
                  >
                    <FaApple /> Apple Music
                  </a>
                </li>
                <li>
                  <a
                    type="text/html"
                    aria-label="Xandor Meet You There (ft. Will Jay) Youtube"
                    href="https://youtu.be/3ZFUFwMPYko"
                  >
                    <FaYoutube /> Youtube
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col sm="12" md="6" lg="4" className="mt-4">
            <Img
              className="rounded-0"
              fluid={props.data.echoCover.childImageSharp.fluid}
              alt="XANDOR Single Echo"
            />
            <h2 className="title">Echo (with Blakey)</h2>
            <p className="tag">2021 &nbsp;·&nbsp; Single</p>
            <div className="services">
              Listen:
              <ul>
                <li>
                  <a
                    type="text/html"
                    aria-label="Xandor Echo (with Blakey) Spotify"
                    href="https://open.spotify.com/track/5VD9LtZhqoctpMxuQuLwwn?si=ea08f2b60f2c48c3"
                  >
                    <FaSpotify /> Spotify
                  </a>
                </li>
                <li>
                  <a
                    type="text/html"
                    aria-label="Xandor Echo (with Blakey) Apple Music"
                    href="https://music.apple.com/us/album/echo/1575030295?i=1575030296"
                  >
                    <FaApple /> Apple Music
                  </a>
                </li>
                <li>
                  <a
                    type="text/html"
                    aria-label="Xandor Echo (with Blakey) Youtube"
                    href="https://youtu.be/BeF-0HRdccA"
                  >
                    <FaYoutube /> Youtube
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col sm="12" md="6" lg="4" className="mt-4">
            <Img
              className="rounded-0"
              fluid={props.data.insatiableCover.childImageSharp.fluid}
              alt="XANDOR Single Insatiable"
            />
            <h2 className="title">Insatiable (ft. Katie Dicicco)</h2>
            <p className="tag">2020 &nbsp;·&nbsp; Single</p>
            <div className="services">
              Listen:
              <ul>
                <li>
                  <a
                    type="text/html"
                    aria-label="Xandor Insatiable (ft. Katie Dicicco) Spotify"
                    href="https://open.spotify.com/track/0hctprSZ0qEr61Rm5BIF4Q?si=682d63d4e2eb4d5a"
                  >
                    <FaSpotify /> Spotify
                  </a>
                </li>
                <li>
                  <a
                    type="text/html"
                    aria-label="Xandor Insatiable (ft. Katie Dicicco) Apple Music"
                    href="https://music.apple.com/us/album/insatiable-feat-katie-dicicco/1530134990?i=1530134991"
                  >
                    <FaApple /> Apple Music
                  </a>
                </li>
                <li>
                  <a
                    type="text/html"
                    aria-label="Xandor Insatiable (ft. Katie Dicicco) Youtube"
                    href="https://youtu.be/aTFo3XjB9f8"
                  >
                    <FaYoutube /> Youtube
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </section>
      <section className="press" id="press">
        <Row>
          <Col lg="12">
            <h1 className="text-center  mt-3 mb-5">Top Press</h1>
          </Col>
          <Col>
            <div className="article">
              <h2>
                <a href="https://www.youredm.com/2022/02/11/xandor-releases-glistening-music-video-to-accompany-deep-house-single-alive/">
                  Xandor Releases Glistening Music Video To Accompany Deep House
                  Single “Alive”
                </a>
              </h2>
              <p>
                Boston native <strong>XANDOR</strong> released a glistening
                music video to accompany his sensational deep house single
                “Alive“, that whisks you away into a breathtaking, west coast
                adventure for an entire 4 minutes. It’s a blissful romance
                between two loving individuals...
                <br />{" "}
                <span className="text-muted">
                  {" "}
                  <strong>YOUREDM</strong> - February 11, 2022
                </span>{" "}
              </p>
            </div>

            <div className="article">
              <h2>
                <a href="https://dancingastronaut.com/2022/02/xandor-sets-out-to-make-streamers-feel-alive-in-first-single-of-2022/">
                  XANDOR sets out to make streamers feel ‘ALIVE’ with first
                  single of 2022
                </a>
              </h2>
              <p>
                For a West Coast escape from the daily quotidian, look no
                further than <strong>XANDOR's</strong> “ALIVE.” Written during
                the COVID-19 quarantine alongside Clara Hurtado, the
                dance-pop-driven single—XANDOR’s first of the new calendar
                year—conveys the message “that we are alive, and that’s a gift
                we shouldn’t take for granted...
                <br />{" "}
                <span className="text-muted">
                  <strong>Dancing Astronaut</strong> - February 11, 2022
                </span>{" "}
              </p>
            </div>

            <div className="article">
              <h2>
                <a href="https://www.edmtunes.com/2020/09/xandor-teams-up-with-vocalist-katie-dicicco-to-bring-us-a-pop-heavy-deep-house-tune-in-insatiable/">
                  XANDOR Teams Up With Vocalist Katie Dicicco To Bring Us A Pop
                  Heavy Deep House Tune in Insatiable
                </a>
              </h2>
              <p>
                Making his EDMTunes debut is deep house maestro,{" "}
                <strong>XANDOR</strong>, and he’s bringing vocalist Katie
                Dicicco along for the ride. <strong>XANDOR</strong> is a
                DJ/Record Producer, who has come to be known for his take on the
                future deep house genre. His music contains silky, sensual
                soundscapes... <br />{" "}
                <span className="text-muted">
                  <strong>EDMTunes</strong> - September 25, 2020
                </span>{" "}
              </p>
            </div>
            <Link to="/press" className="btn btn-outline-primary">
              See all XANDOR's recent articles
            </Link>
          </Col>
        </Row>
      </section>
      <section className="contact" id="contact">
        <Row>
          <Col lg="12">
            <h1 className="text-center  mt-3 mb-5">Contact</h1>
          </Col>
          <Col className="text-center">
            <p>
              <strong>EMAIL: </strong>
              <a
                type="text/html"
                aria-label="Hello Xandor"
                href="mailto:hello@xandormusic.com"
              >
                hello@xandormusic.com
              </a>
            </p>
            {/* <p><strong>MGMT: </strong><a type="text/html" aria-label="Management Xandor" href="mailto:justin@xenomanagment.com">justin@xenomanagment.com</a></p> */}
          </Col>
        </Row>
      </section>
    </Container>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 536, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    xandorImage: file(relativePath: { eq: "xandor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1365, maxHeight: 1365, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    whosInYourHeartCover: file(
      relativePath: { eq: "covers/whos-in-your-heart.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 1200, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    aliveCover: file(relativePath: { eq: "covers/alive.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 1200, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    echoCover: file(relativePath: { eq: "covers/echo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 1200, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    meetYouThereCover: file(relativePath: { eq: "covers/meet-you-there.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 1200, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    insatiableCover: file(relativePath: { eq: "covers/insatiable.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 1200, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
